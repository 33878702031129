import { ComponentProps } from "react";

import { cn } from "~/styles";

export function Button({
  className,
  variant = "primary",
  ...props
}: { variant: "primary" | "outline" } & ComponentProps<"button">) {
  return (
    <button
      className={cn(
        "rounded-md px-4 font-title py-1 outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary",
        {
          "bg-primary hover:bg-primary-900 transition-colors": variant === "primary",
          "border-2 hover:bg-primary/20 border-primary hover:border-primary-900 transition-colors py-0":
            variant === "outline",
        },
        className
      )}
      {...props}
    />
  );
}
